.nav-item{
    position: relative;
}

.nav-item::after{
    position: absolute;
    content: "";
    display: block;
    width: calc(0% - 60px);
    height: 2px;
    background: var(--app-theme);
    transition: width 350ms ease-out;
}

.nav-item:hover::after{
    width: calc(100% - 5px);
}

@media screen and (max-width: 1024px) {
    .nav-header{
        flex-basis: 100%;
    }

    .nav-toggler{
        display: block;
    }

    .nav-menu{
        /* display: none; */
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        background: rgba(41, 41, 41, 0.747);
        height: 100vh;
        width: 0vw;
        opacity: .1;
        overflow: hidden;
        transition: all 0s ease-in;
    }

    .nav-menu.open{
        /* display: block; */
        width: 100vw;
        opacity: 1;
    }

    .nav-menu-nav-wrapper{
        position: relative;
        height: 100%;
        width: 350px;
        background: rgb(31, 41, 55, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-100%);
        transition: all .3s ease-in;
    }

    .nav-menu.open > .nav-menu-nav-wrapper{
        transform: translateX(0);
    }

    .nav-menu-nav{
        overflow-y: auto;
        height: 80%;
        flex-basis: 100%;
        flex-direction: column;
        box-shadow: ;
        /* background: red; */
    }

    .nav-item{
        color: white;
        margin: 30px 0;
    }

    .nav-item:hover > .nav-link{
        color: #ccc;
    }

}

@media screen and (max-width: 768px) {
    .nav-logo{
        width: 180px;
        height: 100px;
    }
}

