.intro-header::before{
    position: absolute;
    content: '';
    background: rgba(0,0,0,.7);
    height: 100%;
    width: 100%;
    z-index: 1;
}

.intro-header-content-wrapper {
    z-index: 1;
}



h1.h1-underlined {
    margin-bottom: 15px;
    padding-bottom: 15px;
}

h1.h1-underlined::after{
    position: absolute;
    content: "";
    background: var(--app-theme);
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
}

@media screen and (max-width: 768px){
    h1.h1-underlined::after{
        right: 0;
        width: 100px;
        margin: auto;
    }
}


/* aims and objectives */
ul.objectives-list{
    margin: 20px 0;
}

ul.objectives-list > li {
    margin-bottom: 15px;
}

ul.objectives-list > li:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #d44040;
    font-size: 11px;
    font-weight: 300;
    padding-right: 8px;
}

