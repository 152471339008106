@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family: myFont2;
  src: url('./assets/fonts/bitter/static/Bitter-Regular.ttf');
}


a:hover{
  /* border: 2px rgba(88, 88, 88, 0.74) solid; */
}

body {
  color: #333;
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  font-family: myFont2, sans-serif;
  /* font-family: myFont2, monospace; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem/* 18px */;
  line-height: 1.75rem/* 28px */;
  /* background: rgb(66, 168, 66); */
  /* bakground: white; */
  /* background: #d44040; */
  /* background: rgb(27, 27, 27); */
  /* background: linear-gradient(90deg,#d44040,#ec4f4f); */
}

html.dark body{
  background: rgb(22, 22, 22);
}

:root{
  --app-theme: linear-gradient(90deg, #d43f3f, #fd6666);
}

.bg-app-theme{
  background: linear-gradient(90deg, #d43f3f, #fd6666);
}

.text-app-lg {
  font-size: 1.1rem/* 18px */;
  line-height: 1.75rem/* 28px */;
}

.text-888{
  color: #888888
}


/* .nav-link{
  font-family: "Lato",sans-serif;
} */